//import css
import "./../../styles/cron-expression-generator.css";
import "./../../styles/products.css";
//import components
import GetStartedBanner from "./../../components/get-started-footer";

import getCronString from '@darkeyedevelopers/natural-cron.js';
import cronstrue from 'cronstrue';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Layout from "./../../components/layout";
import SEO from "./../../components/seo";



const HeadingSection = ({ setInput, setOutput, mode, setMode, setShowOutput }) => {
  const [currentValue, setCurrentValue] = useState('');
  const inputRef = useRef(null);
  const placeholders = [
    "Every Minute",
    "Every 5 minutes",
    "Every hour",
    "Every day at 7:00 AM",
    "Every Monday at 5:30 PM",
    "Every 15th of the month at 10:15 AM",
    "Every 2nd of March at 5:30 PM",
    "Every 1st of January at midnight",
    "Every Friday at 8:00 AM",
    "Every 3rd of July at 4:45 PM",
    "Every Wednesday at noon",
    "Every 30th of August at 11:59 PM",
  ];
  let index = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (inputRef.current) {
        inputRef.current.placeholder = placeholders[index];
        index = (index + 1) % placeholders.length;
      }
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  const clickHandler = () => {
    setInput(currentValue);
    if(mode === 'englishToCron'){
    setOutput(getCronString(currentValue, 'MIN HOR DOM MON WEK').replaceAll('?', '*'));
    }
    else{
      setOutput(cronstrue.toString(currentValue));
    }
    setShowOutput(true); 
  }

  const clearStates = () => {
    setCurrentValue('');
    setInput('');
    setOutput('');
    setShowOutput(false);
  }

  return (
    <div className="u-features freetools-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
          Generate cron schedules in everyday English.
        </h1>
        <div className="FreeTool-heading__description">
          Just tell us the time you’d like the job to run,
          and our tool will convert your instructions into a valid cron expression.
        </div>
      </div>

      {/* Toggle Buttons */}
      <div className="mode-toggle-buttons">
        <button
          className={`mode-button ${mode === 'englishToCron' ? 'active' : ''}`}
          onClick={() => { clearStates(); setMode('englishToCron') }}
        >
          English to Cron
        </button>
        <button
          className={`mode-button ${mode === 'cronToEnglish' ? 'active' : ''}`}
          onClick={() => { clearStates(); setMode('cronToEnglish') }}
        >
          Cron to English
        </button>
      </div>


      <div className="FreeTool-heading__input-button">
        <input
          type="text"
          value={currentValue}
          onChange={(event) => setCurrentValue(event.target.value)}
          ref={inputRef}
          className="cron-expression-input-url"
        />
        <button className="generate-button" onClick={clickHandler}>Generate</button>
      </div>
    </div>
  );
};

const EveryMinuteSection = ({ input, output }) => {
  const [highlightIndex, setHighlightIndex] = useState(null);
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);


  const handleLabelClick = (index) => {
    setHighlightIndex(index);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(output);
    setCopied(true);
    setShowTooltip(true); 

    setTimeout(() => {
      setCopied(false); 
      setShowTooltip(false); 
    }, 1900);
  };

  const splitResult = useMemo(() => {
    if (!output || output.split(' ').length === 0) return Array(5).fill('*');
    return output.split(' ');
  }, [output])

  return (
    <div className="every-minute-section">
      <h2>{input.toUpperCase()}</h2>
      <div className="cron-visualization">
      <div className="cron-values">
        {splitResult.map((item, index) => (
        <div key={index} className="cron-item">
        <span className={`cron-star ${highlightIndex === index ? "highlighted" : ""}`}>
          {item}
        </span>
        <span 
          className={`cron-label ${highlightIndex === index ? "highlighted" : ""}`}
          onClick={() => handleLabelClick(index)}
          style={{ userSelect: "none" }} 
        >
          {["Minute (0-59)", "Hour (0-23)", "Day of the month (1-31)", "Month (1-12 OR JAN-DEC)", "Day of the week (0-7 OR SUN-SAT)"][index]}
        </span>
      </div>
    ))}

<div className="tooltip-container">
      <button onClick={handleCopyClick} className="copy-button">
        {!copied ? (
          <svg className="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
          </svg>
        ) : (
          <svg className="icon copied-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
          </svg>
        )}
      </button>

      {/* Tooltip */}
        <div className={`tooltip ${copied || showTooltip ? "visible" : ""}`}>
        {copied ? "Copied!" : "Copy to clipboard"}
        </div>
        </div>

        </div>
      </div>
    </div>
  );
};

const CronToEnglishSection = ({ output }) => {
  return (
    <div className="cron-to-english-section">
          <div className="every-minute-section">
      <h2>Cron to English</h2>
      <p>{output}</p>
    </div>
    </div>
  );
};

const WhatDoesStarMeanSection = () => {
  return (
    <div className="what-does-star-mean-section">
      <h2>What Does Each Star Mean?</h2>
      <table className="star-meaning-table">
        <thead>
          <tr>
            <th>*</th>
            <th>*</th>
            <th>*</th>
            <th>*</th>
            <th>*</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>minute</td>
            <td>hour</td>
            <td>day of the month</td>
            <td>month</td>
            <td>day of the week</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};


const ExamplesofCronExpressionsSection = () => {
  const cronExamples = [
    { expression: "* * * * *", schedule: "Every minute" },
    { expression: "*/5 * * * *", schedule: "Every 5 minutes" },
    { expression: "0 * * * *", schedule: "Every hour" },
    { expression: "0 7 * * *", schedule: "Every day at 7:00 AM" },
    { expression: "30 17 * * 1", schedule: "Every Monday at 5:30 PM" },
    { expression: "15 10 15 * *", schedule: "Every 15th of the month at 10:15 AM" },
    { expression: "30 17 2 3 *", schedule: "Every 2nd of March at 5:30 PM" },
    { expression: "0 0 1 1 *", schedule: "Every 1st of January at midnight" },
    { expression: "0 8 * * 5", schedule: "Every Friday at 8:00 AM" },
    { expression: "45 16 3 7 *", schedule: "Every 3rd of July at 4:45 PM" },
    { expression: "0 12 * * 3", schedule: "Every Wednesday at noon" },
    { expression: "59 23 30 8 *", schedule: "Every 30th of August at 11:59 PM" },
  ];

  return (
    <div className="examples-of-cron-expressions-section">
      <h2>Examples of Cron Expressions</h2>
      <table className="cron-examples-table">
        <thead>
          <tr>
            <th>Cron Expression</th>
            <th>Schedule</th>
          </tr>
        </thead>
        <tbody>
          {cronExamples.map((example, index) => (
            <tr key={index}>
              <td>{example.expression}</td>
              <td>{example.schedule}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


const CronJobBodySection = () => {
  return (
    <section className="cron-job-body-section">
      <div className="container">
        <h2>Why Choose Our Free Cron Conversion Tool?</h2>
        <p>
          Our free online cron expression generator stands out because it can convert
          plain English descriptions of your desired schedule into a valid cron
          expression—no specialized syntax required.
        </p>

        <h2>How to Use Our Cron Expression Generator</h2>
        <ol>
          <li>
            <strong>Describe the Schedule :</strong>
            Simply type your schedule as you would normally say it in English.
          </li>
          <li>
            <strong>Create the Cron Expression :</strong>
            Let the generator produce the appropriate cron expression based on your input.
          </li>
          <li>
            <strong>Save and Implement :</strong>
            Copy the generated cron expression into your crontab or scheduling system.
          </li>
        </ol>

        <h2>Understanding Cron</h2>
        <h3>What Is Cron?</h3>
        <p>
          Cron is a job scheduler found in Unix-like operating systems.
          It automates the execution of scripts or commands at specified
          times—daily, weekly, monthly, or any custom interval. Common
          uses include system backups, data processing, and other repetitive maintenance tasks.
        </p>

        <h3>What Is a Cron Job?</h3>
        <p>
          A cron job is a specific command or script paired with a scheduling
          pattern that tells the system when to run it. Defined in the crontab
          file, each cron job consists of a timing expression (the cron expression) and the action to execute.
        </p>

        <h3>What Is Crontab?</h3>
        <p>
          Crontab is the configuration file (or "cron table") that holds all your scheduled jobs.
          Each line in this file includes a cron expression followed by the command or script you want to run.
          You can edit your crontab using the <code>crontab -e</code> command in a Unix-like environment.
        </p>

        <h3>Cron Expression Format</h3>
        <p>
          A standard cron expression is typically made up of five fields
          (or six, in some implementations) separated by spaces:
        </p>
        <pre>* * * * * command_to_execute</pre>
        <p>
          Each position in the expression corresponds to a specific time unit:
        </p>
        <ul>
          <li><strong>Minute </strong>(0–59)</li>
          <li><strong>Hour </strong>(0–23)</li>
          <li><strong>Day of Month </strong>(1–31)</li>
          <li><strong>Month </strong>(1–12)</li>
          <li><strong>Day of Week </strong>(0–7, with 0 and 7 both meaning Sunday)</li>
        </ul>

        <h3>Special Characters</h3>
        <ul>
          <li><strong>*</strong>: Matches all valid values (e.g., “every minute” or “every hour”).</li>
          <li><strong>,</strong>: Specifies multiple values (e.g., 1,2,5,9).</li>
          <li><strong>-</strong>: Indicates a range (e.g., 1-5).</li>
          <li><strong>/</strong>: Sets a step value (e.g., */2 means every two minutes).</li>
          <li><strong>?</strong>: No particular value, often used instead of * in the Day of Month or Day of Week field to prevent conflicts.</li>
          <li><strong>L</strong>: Designates the last day of the month or the last specific day of the week (e.g., L in Day of Month runs the command on the month’s final day).</li>
          <li><strong>W</strong>: Moves to the closest weekday (e.g., 15W targets the nearest weekday to the 15th).</li>
          <li><strong>#</strong>: Specifies the nth occurrence of a certain weekday in a month (e.g., 3#2 is the second Wednesday).</li>
        </ul>

        <h3>What Is Quartz Cron?</h3>
        <p>
          Quartz Cron expressions are used by the Quartz Scheduler, a Java-based task scheduling library.
          While similar to traditional Unix cron expressions, Quartz expressions have seven fields (instead of five or six),
          adding extra precision and flexibility.
        </p>
        <pre>S M H D M W Y command_to_execute</pre>
        <h3>
          Explanation of Fields:
        </h3>
        <ul>
          <li><strong>Second (S): </strong>0–59</li>
          <li><strong>Minute (M): </strong>0–59</li>
          <li><strong>Hour (H): </strong> 0–23</li>
          <li><strong>Day of Month (D): </strong> 1–31</li>
          <li><strong>Month (M): </strong> 1–12 or JAN–DEC</li>
          <li><strong>Day of Week (W): </strong> 0–7 or SUN–SAT (0/7 = Sunday)</li>
          <li><strong>Year (Y): </strong>(Optional) 1970–2099</li>
        </ul>

        <p>
          Whether you’re using a standard Unix cron or the extended Quartz cron,
          our free tool simplifies the process by letting you describe your schedule
          in everyday language before converting it into the correct cron expression.
        </p>
      </div>
    </section>
  );
};

const CronExpressionGenerator = ({ data }) => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [mode, setMode] = useState('englishToCron');
  const [showOutput, setShowOutput] = useState(false);

  return (
    <Layout>
      <SEO
        title=" Cron Expression Generator - Odown Free Tools"
        description="Experience our free online cron expression generator,
        where you can create valid cron schedules by simply describing 
        your desired timing in plain language."
        pathname={`/free-tools/cron-expression-generator/`}
        image={{
          src: "/general-image-og--Cron_Expression_Generator.jpg",
          width: 1600,
          height: 800,
        }}
      />
      {/* Heading Section */}
      <HeadingSection setInput={setInput} setOutput={setOutput} mode={mode} setMode={setMode} setShowOutput={setShowOutput} />
      
      {mode === 'englishToCron' && <EveryMinuteSection input={input} output={output} />}
      
      {mode === 'cronToEnglish' && showOutput && <CronToEnglishSection output={output} />}
      

      {/* Common Sections for Both Modes */}
      <WhatDoesStarMeanSection />
      <ExamplesofCronExpressionsSection />
      <CronJobBodySection />
      <GetStartedBanner />
    </Layout>
  )
};

export default CronExpressionGenerator
